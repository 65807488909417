<template>
	<b-card no-body class="card-statistics">

		<b-overlay :show="isFetchingRecordsFromServer" :variant="'transparent'" :opacity="'1'" :blur="'2px'" rounded="sm">
			
			<b-card-header>
				<b-card-title>Filters</b-card-title>
			</b-card-header>
			
			<b-card-body>

				<b-row>

					<b-col class="m-50" xl="3" sm="12">
						<Select2 v-model="selectedSite" :options="userSitesDropDownArray" @change="selectUserSiteChangeEvent($event)" placeholder="Select Site" />
					</b-col>

					<b-col class="m-50" xl="2" sm="12">
						<Select2 v-model="dateTypeValue" :options="dateTypeOptions" @change="dropDownDateTypeChangeEvent($event)" />
					</b-col>

					<b-col class="m-50" xl="3" sm="12" v-if="dateTypeValue == 'custom_date'">
						<VueCtkDateTimePicker :id="'customDateTypeFilter_'+_uid" v-model="customDateTypeFilter" :overlay="true" :noShortcuts="true" :range="true" :maxDate="currentDate" :onlyDate="true" :time="false" format="MM/DD/YYYY" formatted="ll" @is-hidden="onHideChangeCustomDateEvent" />
					</b-col>

					<b-col class="m-50 d-flex justify-content-center" xl="2" sm="12">
						<b-form-group class="mt-50">
							<b-form-checkbox id="remember-me" v-model="isCompareOn" name="checkbox-1" @change="isCompareOnChangeEvent($event)" value="1" unchecked-value="0" :stacked="isCompareOn">
								Compare With
							</b-form-checkbox>
						</b-form-group>
					</b-col>

					<b-col v-if="isCompareOn == 1" class="m-50" xl="2" sm="12">
						<Select2 v-model="compareDateTypeValue" :options="dateTypeOptions" @change="dropDownCompareDateTypeChangeEvent($event)" />
					</b-col>

					<b-col class="m-50" xl="3" sm="12" v-if="isCompareOn == 1 && compareDateTypeValue == 'custom_date'">
						<VueCtkDateTimePicker :id="'toComparecustomDateTypeFilter_'+_uid" v-model="compareCustomDateTypeFilter" :overlay="true" :noShortcuts="true" :range="true" :maxDate="currentDate" :onlyDate="true" :time="false" format="MM/DD/YYYY" formatted="ll" @is-hidden="onHideChangeCompareCustomDateEvent" />
					</b-col>

				</b-row>

			</b-card-body>

		</b-overlay>

	</b-card>
</template>
<script>
	import dateTypeListOptions from "@/libs/dateTypeListOptions";
	import moment from "moment"
	import {
		BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink, BOverlay, BFormCheckbox, BFormGroup
	} from 'bootstrap-vue';
	export default {
		components: {
			BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BMedia, BAvatar, BMediaAside, BMediaBody, BOverlay, BFormCheckbox, BFormGroup
		},
		data() {
			return {
				isFetchingRecordsFromServer: false,
				dateTypeValue: 'today',
				customDateTypeFilter: '',
				compareDateTypeValue: 'today',
				compareCustomDateTypeFilter: '',
				isCompareOn: false,
				userSitesDropDownArray: [],
				userSitesArray: [],
				filterSitesArray: [],
				selectedSite: '',
				currentDate: moment().format("YYYY-MM-DD hh:mm a"),
				dateAndTime: null,
			}
		},
		created() {
			this.getDefaultFilterElements(); /* FETCH FILTER FROM VUEX */
			this.fetchUserSites();
		},
		methods: {
			fetchUserSites() {
				this.isFetchingRecordsFromServer = true;
				this.$globalAPI.get('site/list', {
					status: 1,
					user_id: this.$root.authUserObject?.id
				}).then((response) => {
					response = response.data;
					if (response.status) {
						let sortedSitesArray = _.sortBy(response.sites, "siteURL");
						for (const site of sortedSitesArray) {
							this.userSitesDropDownArray.push({
								'id': site.siteId,
								'text': site.siteURL,
							});
						}
						this.userSitesArray = sortedSitesArray;

						if (!_.isEmpty(sortedSitesArray)) {
							let firstSite = _.first(sortedSitesArray);
							this.filterSitesArray = firstSite;
							this.selectedSite = this.$root.appSiteObject.siteId; /* SELECTED FIRST SITE AND CALL CHANGE EVENT */
						}
					}
					this.isFetchingRecordsFromServer = false;
					this.selectUserSiteChangeEvent(this.$root.appSiteObject.siteId)
					this.fetchCountStatisticsItems();
				});
			},
			dropDownDateTypeChangeEvent(val) {
				if (this.dateTypeValue != 'custom_date') {
					this.fetchCountStatisticsItems();
				}

				/* STORE FILTER IN VUEX AND DB : START */
				this.$root.setDefaultFilterElements({
					page: 'comparisonReport',
					filterObject: {
						'date_type': val,
					},
				});
				/* STORE FILTER IN VUEX AND DB : END */

			},
			dropDownCompareDateTypeChangeEvent(val) {
				if (this.compareDateTypeValue != 'custom_date') {
					this.fetchCountStatisticsItems();
				}

				/* STORE FILTER IN VUEX AND DB : START */
				this.$root.setDefaultFilterElements({
					page: 'comparisonReport',
					filterObject: {
						'compare_date_type': val,
					},
				});
				/* STORE FILTER IN VUEX AND DB : END */

			},
			onHideChangeCustomDateEvent() {
				if (!_.isEmpty(this.customDateTypeFilter)) {
					this.fetchCountStatisticsItems();

					/* STORE FILTER IN VUEX AND DB : START */
					this.$root.setDefaultFilterElements({
						page: 'comparisonReport',
						filterObject: {
							'date_type_custom_filter': this.customDateTypeFilter,
						},
					});
					/* STORE FILTER IN VUEX AND DB : END */

				}
			},
			onHideChangeCompareCustomDateEvent() {
				if (!_.isEmpty(this.compareCustomDateTypeFilter)) {
					this.fetchCountStatisticsItems();

					/* STORE FILTER IN VUEX AND DB : START */
					this.$root.setDefaultFilterElements({
						page: 'comparisonReport',
						filterObject: {
							'compare_date_type_custom_filter': this.compareCustomDateTypeFilter
						},
					});
					/* STORE FILTER IN VUEX AND DB : END */

				}
			},
			selectUserSiteChangeEvent(siteId) {
				this.filterSitesArray = _.first(_.filter(this.userSitesArray, (item) => { /* FOR FILTER SITES WITH SEARCH */
					return ((item.siteId == siteId) ? true : false);
				}));
				this.fetchCountStatisticsItems();
			},
			isCompareOnChangeEvent(val) {
				this.fetchCountStatisticsItems();

				/* STORE FILTER IN VUEX AND DB : START */
				this.$root.setDefaultFilterElements({
					page: 'comparisonReport',
					filterObject: {
						'is_compare_on': val,
					},
				});
				/* STORE FILTER IN VUEX AND DB : END */

			},
			fetchCountStatisticsItems() {

				this.$emit('selectedFilter', {
					'site': this.filterSitesArray,
					'selectedSite': this.selectedSite ?? '',
					'is_compare_on': this.isCompareOn,
					'date_type': this.dateTypeValue,
					'date_type_custom_filter': (this.customDateTypeFilter ? JSON.stringify(this.customDateTypeFilter) : ''),
					'compare_date_type': this.compareDateTypeValue,
					'compare_date_type_custom_filter': (this.compareCustomDateTypeFilter ? JSON.stringify(this.compareCustomDateTypeFilter) : ''),
				});

			},

			/**
			* GET FILTER FROM VUEX AND SET INTO ELEMENTS
			*/
			getDefaultFilterElements() {
				let filterObject = this.$store.getters['appModuleFilters/getFilter']({
					page: 'comparisonReport',
				});

				this.dateTypeValue = filterObject.date_type ? filterObject.date_type : "today";
				this.customDateTypeFilter = filterObject.date_type_custom_filter;
				this.isCompareOn = filterObject.is_compare_on;
				this.compareDateTypeValue = filterObject.compare_date_type;
				this.compareCustomDateTypeFilter = filterObject.compare_date_type_custom_filter;
			}
		},
		setup() {

			const {
				dateTypeOptions,
			} = dateTypeListOptions()

			return {
				dateTypeOptions
			}
		},
	}
</script>